import React from "react"

import { useCore } from "../../../hooks/useCore"

export const withInvalid = Component => ({ name = "Invalid", page }) => {
  const {
    helpers: { sanityContent },
  } = useCore()

  const title = page?.title
  const content = sanityContent(page?.content)

  Component.displayName = name
  return <Component title={title} content={content} />
}
