import React from "react"
import { graphql } from "gatsby"

import { Invalid as Page } from "../../components/Acccount/Invalid/Invalid"

export const query = graphql`
  query {
    page: sanityPageAccountInvalidToken {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }): JSX.Element => <Page {...props} {...data} />
