import React from "react"

import { withInvalid } from "./withInvalid"
import { Container, Frame, Title, Description } from "../../Form/Form.styled"

export const Invalid = withInvalid(
  ({ title, content }): JSX.Element => (
    <Container width={`md`}>
      <Frame>
        <Title description={content}>{title}</Title>
        <Description>{content}</Description>
      </Frame>
    </Container>
  )
)
